<template>
   <div class="modal-notification">
      <svg-icon-template
         :icon-name="modalSettings.iconName"
         class="modal-notification__img"
         alt="Оповещение"
      />

      <h2 class="modal-notification__title">{{ modalSettings.message }}</h2>
   </div>
</template>

<script>
   import SvgIconTemplate from '@/components/templates/SvgIconTemplate';

   export default {
      name: 'ModalNotification',

      components: {
         SvgIconTemplate
      },

      props: {
         modalSettings: { type: Object, required: true }
      }
   };
</script>
